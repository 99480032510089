import { createRouter, createWebHashHistory } from 'vue-router'
import layout from '@/layout/index'
import store from '@/store'

/**
 * 私有路由表
 */
export const privateRoutes = [
]

/**
 * @description: 公开路由表
 */
export const publicRoutes = [
  {
    path: '/login',
    component: () => import('@/views/login/index')
  },
  {
    path: '/',
    // 注意：带有路径“/”的记录中的组件“默认”是一个不返回 Promise 的函数
    component: layout,
    redirect: '/home',
    children: [
      {
        path: '/home',
        name: 'home',
        component: () => import('@/views/home/index'),
        meta: {
          title: 'home',
          icon: 'el-icon',
          iconName: 'House'
        }
      },
      {
        path: '/userCenter',
        name: 'userCenter',
        component: () => import('@/views/subaccount/userCenter'),
        meta: {
          title: 'userCenter',
          icon: 'supplychain-personcenter'
        }
      },
      {
        path: '/404',
        name: '404',
        component: () => import('@/views/error-page/404')
      },
      {
        path: '/401',
        name: '401',
        component: () => import('@/views/error-page/401')
      }
    ]
  },
  {
    path: '/report',
    name: 'report',
    component: layout,
    meta: {
      title: 'report',
      icon: 'supplychain-report'
    },
    children: [
      {
        path: '/report/template',
        name: 'reportTemplate',
        component: () => import('@/views/report/template'),
        meta: {
          title: 'reportTemplate',
          icon: 'supplychain-report-main'
        }
      },
      {
        path: '/report/skuCost',
        name: 'reportSkuCost',
        component: () => import('@/views/report/skucost'),
        meta: {
          title: 'reportSkuCost',
          icon: 'supplychain-report-main'
        }
      },
      {
        path: '/report/operateFee',
        name: 'reportOperateFee',
        component: () => import('@/views/report/operatefee'),
        meta: {
          title: 'reportOperateFee',
          icon: 'supplychain-report-main'
        }
      },
      {
        path: '/report/excel',
        name: 'reportExcel',
        component: () => import('@/views/report/excel'),
        meta: {
          title: 'reportExcel',
          icon: 'supplychain-report-main'
        }
      },
      {
        path: '/report/list',
        name: 'reportList',
        component: () => import('@/views/report/list'),
        meta: {
          title: 'reportList',
          icon: 'supplychain-report-main'
        }
      }
      // {
      //   path: '/report/team',
      //   name: 'reportSummary',
      //   component: () => import('@/views/report/index'),
      //   meta: {
      //     title: 'reportSummary',
      //     icon: 'supplychain-report-main',
      //     isDisabled: true
      //   }
      // },
      // {
      //   path: '/report/category',
      //   name: 'reportStore',
      //   component: () => import('@/views/report/category'),
      //   meta: {
      //     title: 'reportStore',
      //     icon: 'supplychain-report-store',
      //     isDisabled: true
      //   }
      // },
      // {
      //   path: '/report/brand',
      //   name: 'reportGoods',
      //   component: () => import('@/views/report/brand'),
      //   meta: {
      //     title: 'reportGoods',
      //     icon: 'supplychain-report-goods',
      //     isDisabled: true
      //   }
      // }
    ]
  }
]
const router = createRouter({
  history: createWebHashHistory(),
  routes: publicRoutes
})
/**
 * 初始化路由表
 */
export function resetRouter() {
  if (
    store.getters.userInfo &&
    store.getters.userInfo.permission &&
    store.getters.userInfo.permission.menus
  ) {
    const menus = store.getters.userInfo.permission.menus
    menus.forEach((menu) => {
      router.removeRoute(menu)
    })
  }
}
export default router
